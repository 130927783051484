<template>
  <div class="col-lg-7 col-md-7">
    <!-- 推薦不顯示新增貼文 -->
    <div class="col-sm-12">
      <tab-content id="pills-tabContent-2">
        <tab-content-item :active="true" id="profile-feed" aria-labelled-by="pills-feed-tab">
          <SocialPost :post="list" :settions="settions" :showSettions="false" :previewUser="{}" />
        </tab-content-item>
      </tab-content>
    </div>
    <div v-if="commomData.listLoader" class="col-sm-12 text-center">
      <img src="../../../assets/images/page-img/page-load-loader.gif" alt="loader" style="height: 100px" />
    </div>
  </div>
</template>
<script>
import SocialPost from './Components/SocialPost'
import { mapGetters } from 'vuex'
import { commomData } from '@/utils/commonVmodel'
import { getPostById } from '@/api/post'

export default {
  name: 'Post',
  components: { SocialPost },
  setup() {
    return {}
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState',
      isPlan: 'User/isPlanState'
    })
  },
  created() {
    this.getRows()
  },
  mounted() {},
  data() {
    return {
      list: [],
      primaryKey: 'created_at',
      commomData: commomData()
    }
  },
  methods: {
    async getRows() {
      this.commomData.listLoader = true
      this.commomData.condition.order_by = this.primaryKey
      const postType = this.$route.query.postType
      if (postType) {
        this.commomData.condition.post_type = 5
      } else {
        this.commomData.condition.post_type = 1
      }

      this.commomData.condition.post_id = this.$route.params.post_id
      await getPostById(this.commomData.condition.post_id)
        .then(res => {
          this.list = [res.data]
          this.commomData.listLoader = false
        })
        .catch(err => {
          this.commomData.listLoader = true
          console.log(err)
        })
    }
  }
}
</script>
